<auth-page>
    <form (ngSubmit)="resetPassword()" *ngIf="errors$ | async as errors" [formGroup]="form" ngNativeValidate>
        <div class="header" trans>Reset your account password</div>

        <div class="many-inputs">
            <div class="input-container">
                <label for="email" trans>Email</label>
                <input type="email" formControlName="email" id="email" required>
                <p class="error email-error" *ngIf="errors.email">{{errors.email}}</p>
            </div>

            <div class="input-container">
                <label for="password" trans>New Password</label>
                <input type="password" formControlName="password" id="password" required>
                <p class="error password-error" *ngIf="errors.password">{{errors.password}}</p>
            </div>

            <div class="input-container">
                <label for="password_confirmation" trans>Confirm New Password</label>
                <input type="password" formControlName="password_confirmation" name="password_confirmation" id="password_confirmation" required>
            </div>
        </div>

        <button mat-raised-button class="primary-button" color="accent" type="submit" [disabled]="loading$ | async" trans>Reset Password</button>
        <div trans="" class="text-dark" style="margin-top: 30px;">Back to <a trans="" class="primary-color" href="/login">Login</a>
        </div>
    </form>
</auth-page>
