<auth-page>


  <style type="text/css">
    .faconspan {
      float: right;
      margin-right: 6px;
      margin-top: -20px;
      position: relative;
      z-index: 2;

    }
  </style>
  <form (ngSubmit)="login()" [formGroup]="form" *ngIf="errResponse$ | async as errors" ngNativeValidate>
    <p _ngcontent-rkd-c92="" class="welcomeback-text">WELCOME BACK</p>
    <div class="header text-center text-dark" [transValues]="{siteName: settings.get('branding.site_name')}" trans>
      {{ route.snapshot.data.message || 'Sign in to Continue ' }}<div class="after-dot"></div>
    </div>

    <div class="many-inputs">
      <div class="input-container">
        <label for="login-email" trans>Email</label>
        <input type="email" formControlName="email" id="login-email" required />
        <i onclick="show_hide_pwd()" style="position: absolute;
        width: 20px;
        height: 20px;
        bottom: 18.5px;
        right: 10px;
        color:black;
        z-index:1000;
        cursor: pointer;">
          <svg width="20" height="20" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path opacity="0.4"
              d="M22.6667 27.3333H9.33341C5.33341 27.3333 2.66675 25.3333 2.66675 20.6667V11.3333C2.66675 6.66666 5.33341 4.66666 9.33341 4.66666H22.6667C26.6667 4.66666 29.3334 6.66666 29.3334 11.3333V20.6667C29.3334 25.3333 26.6667 27.3333 22.6667 27.3333Z"
              fill="#292D32" />
            <path
              d="M16.0001 17.16C14.8801 17.16 13.7468 16.8133 12.8801 16.1067L8.70678 12.7733C8.28011 12.4267 8.20012 11.8 8.54678 11.3733C8.89345 10.9467 9.52012 10.8667 9.94679 11.2133L14.1201 14.5467C15.1334 15.36 16.8534 15.36 17.8668 14.5467L22.0401 11.2133C22.4668 10.8667 23.1068 10.9333 23.4401 11.3733C23.7868 11.8 23.7201 12.44 23.2801 12.7733L19.1068 16.1067C18.2534 16.8133 17.1201 17.16 16.0001 17.16Z"
              fill="#292D32" />
          </svg>

        </i>
        <!-- <span class="fa fa-unlock-alt faconspan">
          
          
        </span> -->
        <p class="error big-error" *ngIf="errors.email">
          <mat-icon svgIcon="warning"></mat-icon>
          <span>{{ errors.email }}</span>
        </p>
      </div>

      <div class="input-container position-relative">
        <!--  <div class="label-with-action">
          <label for="login-password" trans>Password</label>
          <a class="forgot-password" [routerLink]="['/forgot-password']" tabindex="-1" trans>Forgot your password?</a>
        </div> -->
        <input type="password" class="show-password" formControlName="password" id="login-password" required />

        <i onclick="show_hide_pwd()" id="show-password" style="position: absolute;
        width: 20px;
        height: 20px;
        bottom: 18.5px;
        right: 12px;
        color:black;
        z-index:1000;
        cursor: pointer;">
          <svg width="22" height="22" viewBox="0 0 32 42" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path opacity="0.4"
              d="M33.6458 14.4875C29.9883 8.73998 24.6367 5.43082 19 5.43082C16.1817 5.43082 13.4425 6.25415 10.9408 7.78998C8.43916 9.34165 6.19082 11.6058 4.35416 14.4875C2.77082 16.9733 2.77082 21.0108 4.35416 23.4967C8.01166 29.26 13.3633 32.5533 19 32.5533C21.8183 32.5533 24.5575 31.73 27.0592 30.1941C29.5608 28.6425 31.8092 26.3783 33.6458 23.4967C35.2292 21.0267 35.2292 16.9733 33.6458 14.4875ZM19 25.3967C15.4533 25.3967 12.6033 22.5308 12.6033 19C12.6033 15.4691 15.4533 12.6033 19 12.6033C22.5467 12.6033 25.3967 15.4691 25.3967 19C25.3967 22.5308 22.5467 25.3967 19 25.3967Z"
              fill="#292D32" />
            <path
              d="M19 14.4717C16.5142 14.4717 14.4875 16.4983 14.4875 19C14.4875 21.4858 16.5142 23.5125 19 23.5125C21.4858 23.5125 23.5283 21.4858 23.5283 19C23.5283 16.5142 21.4858 14.4717 19 14.4717Z"
              fill="#292D32" />
          </svg>
        </i>
        <p class="error big-error" *ngIf="errors.password">
          <mat-icon svgIcon="warning"></mat-icon>
          <span>{{ errors.password }}</span>
        </p>
      </div>
    </div>


    <div _ngcontent-rsq-c92="" class="d-flex justify-content-between"
      style="color:black; font-size:13px; margin-top:35px;">
      <div _ngcontent-rsq-c92="" class="label-with-action d-inline-block"
        style="display: inline-block; font-weight:700;">
        <a _ngcontent-rsq-c92="" tabindex="-1" trans="" class="forgot-password" [routerLink]="['/forgot-password']"
          style="color:#FF8881">
          <span _ngcontent-rsq-c92=""></span>
          <div class="after-dot" style="background-color:#FF8881 !important"></div> Forgot your password?
        </a>
      </div>
      <div _ngcontent-rsq-c92="" class="label-with-action d-inline-block" style="display: inline-block; float: right;">
        <span _ngcontent-rsq-c92="" trans="" class="text-dark">Not a member?</span>
        <a _ngcontent-rsq-c92="" trans="" class="register-link primary-color" [routerLink]="['/register']"><span
            style="color:#9C4EFF"> Sign up!</span></a>
      </div>
    </div>
    <button mat-raised-button class="primary-button" type="submit" [disabled]="loading$ | async" trans>
      Sign In
    </button>

    <button type="button" class="no-style alternative-signin-method" (click)="socialAuth.loginWith('envato')"
      *ngIf="settings.get('social.envato.enable'); else defaultSocialAuth">
      <mat-icon svgIcon="envato-custom"></mat-icon>
      <span trans>Sign in with Envato instead</span>
    </button>

    <ng-template #defaultSocialAuth>
      <ng-container *ngIf="!settings.get('registration.disable')">
        <div class="social-icons center">
          <button type="button" mat-icon-button (click)="socialAuth.loginWith('facebook')"
            *ngIf="settings.get('social.facebook.enable')" class="social-icon no-style facebook"
            [matTooltip]="'Login with facebook' | trans">
            <mat-icon svgIcon="facebook"></mat-icon>
          </button>
          <button type="button" mat-icon-button (click)="socialAuth.loginWith('google')"
            *ngIf="settings.get('social.google.enable')" class="social-icon no-style google"
            [matTooltip]="'Login with google' | trans">
            <mat-icon svgIcon="google"></mat-icon>
          </button>
          <button type="button" mat-icon-button (click)="socialAuth.loginWith('twitter')"
            *ngIf="settings.get('social.twitter.enable')" class="social-icon no-style twitter"
            [matTooltip]="'Login with twitter' | trans">
            <mat-icon svgIcon="twitter"></mat-icon>
          </button>
        </div>
      </ng-container>
    </ng-template>
  </form>

</auth-page>