import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { SocialAuthService } from '../social-auth.service';
import { AuthService } from '../auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CurrentUser } from '../current-user';
import { Bootstrapper } from '../../core/bootstrapper.service';
import { Settings } from '../../core/config/settings.service';
import { FormBuilder } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { Toast } from '@common/core/ui/toast.service';
import { BackendErrorResponse } from '@common/core/types/backend-error-response';
import { filter, finalize } from 'rxjs/operators';
import { User } from '@common/core/types/models/User';

@Component({
    selector: 'customdashboard',
    templateUrl: './customdashboard.component.html',
    styleUrls: ['./customdashboard.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomdashboardComponent {
    constructor(){
        console.log('ashish');
        window.location.href = 'https://app.agencysuit.com/customdashboard';
        console.log('ahsish 2');
    }
}
