<auth-page>
    <form (ngSubmit)="register()" *ngIf="errors$ | async as errors" [formGroup]="form" ngNativeValidate>
        <div trans="" class="text-dark" style="margin-bottom: 15px;">START FOR FREE</div>
        <div class="header" [transValues]="{siteName: settings.get('branding.site_name')}" trans>
            {{ route.snapshot.data.message || 'Create New Account' }}
        </div>
        <div trans="" class="text-dark mb-5">Already a member? <a trans="" class="primary-color" href="/login">Login</a>
        </div>

        <div class="many-inputs">
            <div _ngcontent-elg-c95="" class="d-flex align-items-center justify-content-center">
                <!--  -->
                <div class="input-container">
                    <label for="firstname" trans>First Name</label>
                    <input type="firstname" formControlName="first_name" id="firstname" name="first_name" required>
                    <i onclick="show_hide_pwd()" style="position: absolute; width: 20px; height: 20px; bottom: 18.5px; right: 10px; color:black; z-index:1000; cursor: pointer;">
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 32 32" fill="none">
                            <path
                                d="M16 16C19.6819 16 22.6667 13.0152 22.6667 9.33333C22.6667 5.65144 19.6819 2.66667 16 2.66667C12.3181 2.66667 9.33333 5.65144 9.33333 9.33333C9.33333 13.0152 12.3181 16 16 16Z"
                                stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            <path opacity="0.4"
                                d="M27.4533 29.3333C27.4533 24.1733 22.32 20 16 20C9.68 20 4.54667 24.1733 4.54667 29.3333"
                                stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>

                    </i>
                    <p class="firstname" *ngIf="errors.firstname">
                        <mat-icon svgIcon="firstname"></mat-icon>
                        <span>{{errors.firstname}}</span>
                    </p>
                </div>
                <div class="input-container lastname-input-container">
                    <label for="lastname" trans>Last Name</label>
                    <input type="lastname" formControlName="last_name" id="lastname" name="last_name" required>
                    <i onclick="show_hide_pwd()" style="position: absolute;
                    width: 20px;
                    height: 20px;
                    bottom: 18.5px;
                    right: 10px;
                    color:black;
                    z-index:1000;
                    cursor: pointer;">
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 32 32" fill="none">
                            <path
                                d="M16 16C19.6819 16 22.6667 13.0152 22.6667 9.33333C22.6667 5.65144 19.6819 2.66667 16 2.66667C12.3181 2.66667 9.33333 5.65144 9.33333 9.33333C9.33333 13.0152 12.3181 16 16 16Z"
                                stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            <path opacity="0.4"
                                d="M27.4533 29.3333C27.4533 24.1733 22.32 20 16 20C9.68 20 4.54667 24.1733 4.54667 29.3333"
                                stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>

                    </i>
                    <p class="lastname" *ngIf="errors.lastname">
                        <mat-icon svgIcon="lastname"></mat-icon>
                        <span>{{errors.lastname}}</span>
                    </p>
                </div>
                <!--  -->
            </div>
            <div class="input-container">
                <label for="email" trans>Email</label>
                <input type="email" formControlName="email" id="email" required>
                <i onclick="show_hide_pwd()" style="position: absolute;
                    width: 20px;
                    height: 20px;
                    bottom: 18.5px;
                    right: 10px;
                    color:black;
                    z-index:1000;
                    cursor: pointer;">
                    <svg width="20" height="20" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path opacity="0.4"
                            d="M22.6667 27.3333H9.33341C5.33341 27.3333 2.66675 25.3333 2.66675 20.6667V11.3333C2.66675 6.66666 5.33341 4.66666 9.33341 4.66666H22.6667C26.6667 4.66666 29.3334 6.66666 29.3334 11.3333V20.6667C29.3334 25.3333 26.6667 27.3333 22.6667 27.3333Z"
                            fill="#292D32" />
                        <path
                            d="M16.0001 17.16C14.8801 17.16 13.7468 16.8133 12.8801 16.1067L8.70678 12.7733C8.28011 12.4267 8.20012 11.8 8.54678 11.3733C8.89345 10.9467 9.52012 10.8667 9.94679 11.2133L14.1201 14.5467C15.1334 15.36 16.8534 15.36 17.8668 14.5467L22.0401 11.2133C22.4668 10.8667 23.1068 10.9333 23.4401 11.3733C23.7868 11.8 23.7201 12.44 23.2801 12.7733L19.1068 16.1067C18.2534 16.8133 17.1201 17.16 16.0001 17.16Z"
                            fill="#292D32" />
                    </svg>

                </i>
                <p class="error" *ngIf="errors.email">
                    <mat-icon svgIcon="warning"></mat-icon>
                    <span>{{errors.email}}</span>
                </p>
            </div>

            <div class="input-container">
                <label for="register_password" trans>Password</label>
                <input type="password" formControlName="password" id="register_password" required>
                <i onclick="signup_show_hide_pwd(1)" id="show-password_1" style="position: absolute;
                    width: 20px;
                    height: 20px;
                    bottom: 18.5px;
                    right: 12px;
                    color:black;
                    z-index:1000;
                    cursor: pointer;">
                    <svg width="22" height="22" viewBox="0 0 32 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path opacity="0.4"
                            d="M33.6458 14.4875C29.9883 8.73998 24.6367 5.43082 19 5.43082C16.1817 5.43082 13.4425 6.25415 10.9408 7.78998C8.43916 9.34165 6.19082 11.6058 4.35416 14.4875C2.77082 16.9733 2.77082 21.0108 4.35416 23.4967C8.01166 29.26 13.3633 32.5533 19 32.5533C21.8183 32.5533 24.5575 31.73 27.0592 30.1941C29.5608 28.6425 31.8092 26.3783 33.6458 23.4967C35.2292 21.0267 35.2292 16.9733 33.6458 14.4875ZM19 25.3967C15.4533 25.3967 12.6033 22.5308 12.6033 19C12.6033 15.4691 15.4533 12.6033 19 12.6033C22.5467 12.6033 25.3967 15.4691 25.3967 19C25.3967 22.5308 22.5467 25.3967 19 25.3967Z"
                            fill="#292D32" />
                        <path
                            d="M19 14.4717C16.5142 14.4717 14.4875 16.4983 14.4875 19C14.4875 21.4858 16.5142 23.5125 19 23.5125C21.4858 23.5125 23.5283 21.4858 23.5283 19C23.5283 16.5142 21.4858 14.4717 19 14.4717Z"
                            fill="#292D32" />
                    </svg>
                </i>
                <p class="error" *ngIf="errors.password">
                    <mat-icon svgIcon="warning"></mat-icon>
                    <span>{{errors.password}}</span>
                </p>
            </div>

            <div class="input-container">
                <label for="register_password_confirmation" trans>Confirm Password</label>
                <input type="password" formControlName="password_confirmation" id="register_password_confirmation"
                    required>
                <i onclick="signup_show_hide_pwd(2)" id="show-password_2" style="position: absolute;
                    width: 20px;
                    height: 20px;
                    bottom: 18.5px;
                    right: 12px;
                    color:black;
                    z-index:1000;
                    cursor: pointer;">
                    <svg width="22" height="22" viewBox="0 0 32 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path opacity="0.4"
                            d="M33.6458 14.4875C29.9883 8.73998 24.6367 5.43082 19 5.43082C16.1817 5.43082 13.4425 6.25415 10.9408 7.78998C8.43916 9.34165 6.19082 11.6058 4.35416 14.4875C2.77082 16.9733 2.77082 21.0108 4.35416 23.4967C8.01166 29.26 13.3633 32.5533 19 32.5533C21.8183 32.5533 24.5575 31.73 27.0592 30.1941C29.5608 28.6425 31.8092 26.3783 33.6458 23.4967C35.2292 21.0267 35.2292 16.9733 33.6458 14.4875ZM19 25.3967C15.4533 25.3967 12.6033 22.5308 12.6033 19C12.6033 15.4691 15.4533 12.6033 19 12.6033C22.5467 12.6033 25.3967 15.4691 25.3967 19C25.3967 22.5308 22.5467 25.3967 19 25.3967Z"
                            fill="#292D32" />
                        <path
                            d="M19 14.4717C16.5142 14.4717 14.4875 16.4983 14.4875 19C14.4875 21.4858 16.5142 23.5125 19 23.5125C21.4858 23.5125 23.5283 21.4858 23.5283 19C23.5283 16.5142 21.4858 14.4717 19 14.4717Z"
                            fill="#292D32" />
                    </svg>
                </i>
            </div>

            <div class="input-container"
                *ngIf="settings.get('envato.enable') && settings.get('envato.require_purchase_code')">
                <label for="purchase_code" trans>Envato Purchase Code</label>
                <input type="text" formControlName="purchase_code" id="purchase_code" required>
                <p class="error" *ngIf="errors.purchase_code">
                    <mat-icon svgIcon="warning"></mat-icon>
                    <span>{{errors.purchase_code}}</span>
                </p>
            </div>

        </div>

        <div class="policies" *ngIf="registerPolicies.length">
            <mat-checkbox name="remember-me" class="policy-checkbox" *ngFor="let policy of registerPolicies"
                [formControlName]="policy.id" color="accent" required trans>
                <span trans>I accept the</span>&ngsp;
                <a [href]="policy.type === 'link' ? policy.action : (settings.getBaseUrl() + '/' + policy.action)"
                    *ngIf="policy.action" target="_blank">
                    <strong>{{policy.label | trans}}</strong>
                </a>
                <strong *ngIf=!policy.action>{{policy.label | trans}}</strong>
            </mat-checkbox>
        </div>

        <button mat-raised-button class="primary-button" color="accent" type="submit" [disabled]="loading$ | async"
            trans>Create Account</button>

        <ng-container *ngIf="!settings.get('registration.disable')">
            <button type="button" class="no-style alternative-signin-method" (click)="socialAuth.loginWith('envato')"
                *ngIf="settings.get('social.envato.enable'); else defaultSocialAuth">
                <mat-icon svgIcon="envato-custom"></mat-icon>
                <span trans>Sign in with Envato instead</span>
            </button>
            <ng-template #defaultSocialAuth>
                <div class="social-icons center">
                    <button type="button" mat-icon-button (click)="socialAuth.loginWith('facebook')"
                        *ngIf="settings.get('social.facebook.enable')" class="social-icon no-style facebook"
                        [matTooltip]="'Login with facebook' | trans">
                        <mat-icon svgIcon="facebook"></mat-icon>
                    </button>
                    <button type="button" mat-icon-button (click)="socialAuth.loginWith('google')"
                        *ngIf="settings.get('social.google.enable')" class="social-icon no-style google"
                        [matTooltip]="'Login with google' | trans">
                        <mat-icon svgIcon="google"></mat-icon>
                    </button>
                    <button type="button" mat-icon-button (click)="socialAuth.loginWith('twitter')"
                        *ngIf="settings.get('social.twitter.enable')" class="social-icon no-style twitter"
                        [matTooltip]="'Login with twitter' | trans">
                        <mat-icon svgIcon="twitter"></mat-icon>
                    </button>
                </div>
            </ng-template>
        </ng-container>
    </form>
</auth-page>
<div class="create-bg-wrap">
    <div class="create-img-wrap">
        <img width="700" src="https://app.agencysuit.com/public/client/assets/images/create_account_bg.png">
    </div>
</div>